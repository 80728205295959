import Service from "../Service";

const resource = "frztechnicalSpecification/";

export default {
    save(prd, requestID) {
        return Service.post(resource + "save", prd, {
            params: { requestID: requestID },
        });
    },

    getparams(prd, requestID) {
        return Service.post(resource + "getparams", prd, {
            params: { requestID: requestID },
        });
    },

    listByCultive(obj, requestID) {
        return Service.post(resource + "listByCultive", obj,{
            params: { requestID: requestID },
        });
    },

};