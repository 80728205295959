import Service from "../Service";

const resource = "FrzTechnicalspecificationsByCultive/";

export default {

    save(obj, requestID) {
        return Service.post(resource + "save", obj, {
            params: { requestID: requestID },
        });
    },

    listByCultive(obj, requestID) {
        return Service.post(resource + "listByCultive", obj,{
            params: { requestID: requestID },
        });
    },

};