<template>
	<div>
		<v-card dense>
			<v-row style="margin-top: 4px;">
				<v-col cols="12" lg="12" md="12">
					<s-select-definition
						:def="1173"
						@getDataInitial="getCultive($event)"
						hidden
					></s-select-definition>
					<s-select-definition
						:def="1380"
						@getDataInitial="getTypeSampling($event)"
						hidden
					></s-select-definition>

					<v-tabs v-model="currentItem">
						<v-tab 
							v-for="(item, index) in itemsCultive" 
							:key="index"
							@click="clickCultive(item)"
						>
							{{item.DedDescription}}
						</v-tab>
						<!-- <v-tab href="#EntryTunnel"> Ingreso Túnel</v-tab> -->
						
					</v-tabs>
					<v-tabs-items v-model="currentItem">
						<v-tab-item 
							v-for="(item, index) in itemsCultive"
							:key="index"
						>
							<v-container>
								<v-row>
									<v-col>
										<s-toolbar
											save
											color="#E0F7F2"
											
										></s-toolbar>
										<!-- @save="saveParams()" -->
									</v-col>
								</v-row>
								<!-- <v-row>
									<v-col 
										v-for="(item, index) in itemsTypeSampling"
										:key="index"
									>
										<v-switch
											v-model="typeSamplingSelected"
											:value="index.DedValue"
											@input="changeTypeSampling($event, item)"
											:label="item.DedDescription"
										></v-switch>
									</v-col>
								</v-row> -->

								<!-- <v-row>
									<v-col
										v-for="(item, index) in itemsTypeSampling"
										:key="index"
										lg="4"
										md="4"
										sm="6"
										xs="12"
									>
										<v-card
											v-if="test"
											
										>
											<s-toolbar 
												:label="item.DedDescription"
												color="#E0F7F2"
											></s-toolbar>
											<v-container>
												<v-row>
													<v-col>
														<s-text
															:label="item.DedDescription + ' Mínimo'"
														></s-text>
													</v-col>
													<v-col>
														<s-text
															:label="item.DedDescription + ' Máximo'"
														></s-text>
													</v-col>
												</v-row>
											</v-container>
										</v-card>
									</v-col>
								</v-row> -->
								
								
								<!-- <v-row>
									<v-col>
										<s-text
											v-model="specification.BrixMin"
											label="Brix Mínimo"
										></s-text>
									</v-col>
									<v-col>
										<s-text
											v-model="specification.BrixMax"
											label="Brix Máximo"
										></s-text>
									</v-col>
									<v-col>
										<s-text
											v-model="specification.AcidityMin"
											label="Acidez Mínimo"
										></s-text>
									</v-col>
									<v-col>
										<s-text
											v-model="specification.AcidityMax"
											label="Acidez Máximo"
										></s-text>
									</v-col>
								</v-row> -->

								<!-- TABLA DE ESPECIFICACIONES TECNICAS -->
								<v-row>
									<v-col>
										<h2>Especificaciones tecnicas</h2>
										<v-data-table
											:items="itemsSpecificationsCultive"
											:headers="headerSpecifications"
											disable-pagination
										    :hide-default-footer="true">

											<template v-slot:item.FsaMinValue="{ item }">
												<v-progress-linear
													v-if="item.FsaMinValue"
													:value="item.FsaMinValue"
													height="25"
													color="amber"
												>
													<template>
														<strong>{{  Math.ceil(item.FsaMinValue) }}</strong>
													</template>
												</v-progress-linear>
											</template>

											<template v-slot:item.FsaMaxValue="{ item }">
												<v-progress-linear
													v-if="item.FsaMaxValue"
													:value="item.FsaMaxValue"
													height="25"
													color="amber"
												>
													<template>
														<strong>{{  Math.ceil(item.FsaMaxValue) }}</strong>
													</template>
												</v-progress-linear>
											</template>

											<template v-slot:item.actions="{ item }">
												<v-icon
													small
													class="mr-2"
													@click="editSpecifications(item)">
													mdi-pencil
												</v-icon>
											</template>


										</v-data-table>
									</v-col>
								</v-row>

								<!-- TABLA DE % DE DAÑOS  -->
								<v-row>
									<v-col>
										<h2>Daños / Defectos</h2>
										<v-data-table
											:items="itemsDefectByCultive"
											:headers="headersDefect"
											disable-pagination
											:hide-default-footer="true"
										>
											<template v-slot:item.DfcTolerance="{ item }">
												<v-progress-linear
													v-if="item.DfcTolerance"
													:value="item.DfcTolerance"
													height="25"
													color="amber"
												>
													<template>
														<strong>{{  Math.ceil(item.DfcTolerance) }}%</strong>
													</template>
												</v-progress-linear>
											</template>
											<template v-slot:item.actions="{ item }">
												<v-icon
													small
													class="mr-2"
													@click="editDefect(item)">
													mdi-pencil
												</v-icon>
											</template>
										</v-data-table>
									</v-col>
								</v-row>
							</v-container>
						</v-tab-item>
					</v-tabs-items>
				</v-col>
			</v-row>
		</v-card>

		<!-- VENTANA MODAL DE ESPECIFICACIONES  -->
			<v-dialog
			v-model="dialogSpecifications"
			max-width="400px"
			height="100px"
			persistent
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col>
								<s-text
									v-model="FsaMinValueEdit"
									label="Valor Minimo">
								</s-text>
							</v-col>
						
						</v-row>

						<v-row>
							<v-col>
								<s-text
									v-model="FsaMaxValueEdit"
									label="Valor Maximo">
								</s-text>
							</v-col>
							
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="warning"
					text
					@click="closeDialogEditSpecifications()"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="saveSpecifications()"
				>
					Guardar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	

		<!-- VENTANA MODAL DE DAÑOS -->

		<v-dialog
			v-model="dialogDamage"
			max-width="500px"
			persistent
		>
			<v-card>
				<v-card-text>
					<v-container>
						<v-row>
							<v-col
							>
								<s-text
									v-model="DfcToleranceEdit"
									label="Tolerancia"
								>
								</s-text>
							</v-col>
						</v-row>
					</v-container>
				</v-card-text>

				<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="warning"
					text
					@click="closeDialogEditDefect()"
				>
					Cancelar
				</v-btn>
				<v-btn
					color="primary"
					text
					@click="save()"
				>
					Guardar
				</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	
	</div>
</template>

<script>
	import _sDamageFlawCultiveService from "@/services/FrozenProduction/DamageFlawCultiveService.js"
	import _sTechnicalSpecificationService from "@/services/FrozenProduction/FrzTechnicalSpecificationService.js"

	import _sFrzTechnicalspecificationsByCultive from "@/services/FrozenProduction/FrzTechnicalSpecificationsByCultiveService.js";

	import SSwitch from '../../components/Utils/SSwitch.vue'

	export default {
  components: { SSwitch },
		data() {
			return {
				config: {
					model:{
						ID: "int"
					},
					service: "service",
					headers: [
						{text: "ID", value: "ID"}
					]
				},
				itemsCultive: [],
				firstCultive: {},
				currentItem: null,
				dialogSpecifications: false,
				itemsSpecificationsCultive : [],
				selectCultive: "",
				itemsDefectByCultive: [],
				headersDefect: [
					{text: "Defecto", value: "DfcName"},
					{text: "Tolerancia", value: "DfcTolerance"},
					{text: "Acción", value: "actions", width: 100},
				],
				headerSpecifications : [
					{text: "Especificacion", value:"TypEspecificationsName"},
					{text: "Valor Mínimo", value: "FsaMinValue"},
					{text: "Valor Máximo", value: "FsaMaxValue"},
					{text:"Acción", value: "actions", width: 100},
				],
				dialogDamage: false,
				damageSelected: {},
				DfcToleranceEdit: "",
				specification: {},
				FsaMinValueEdit: "",
				FsaMaxValueEdit:"",
				itemsTypeSampling: [],
				observationChange: [],
				typeSamplingSelected: []
			}
		},

		computed: {
			test()
			{
				/* console.log('comp', this.observationChange[0]);
				if(this.observationChange[0] == 1){
					return true;
				}
				else{
					return false;
				} */
				
			}
		},

		methods: {
			getCultive(items) {
				if(items != undefined)
				{
					this.firstCultive = items[0];
					this.getSpecification(items[0]);
					this.getDamageFlawCultive(items[0]);
					this.getParamsByCultive(items[0]);
					this.selectCultive =  '#'+items[0].DedDescription;
				}
				this.itemsCultive = items;
			},

			getSpecification(cultive){
	
				let obj = {
					TypeCultive : cultive.DedValue,
				};

				_sFrzTechnicalspecificationsByCultive.listByCultive(obj, this.$fun.getUserID())
				.then((r)=>{
					if(r.status == 200) {
						console.log('Mostramos david', r.data);
						this.itemsSpecificationsCultive = r.data;
					}
				});
			},

			getDamageFlawCultive(cultive)
			{
				cultive.TypeCultive = cultive.DedValue;
				_sDamageFlawCultiveService
				.list(cultive, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						this.itemsDefectByCultive = resp.data;
						console.log("list damage samir", resp.data);
					}
				})
			},

			getParamsByCultive(cultive)
			{
				this.specification = {};
				cultive.TypeCultive = cultive.DedValue;
				_sTechnicalSpecificationService
				.getparams(cultive, this.$fun.getUserID())
				.then(resp => {
					if(resp.status == 200)
					{
						if(resp.data != null)
						{
							this.specification = resp.data;
						}
						
                        console.log("🚀 ~ file: TechnicalSpecifications.vue ~ line 213 ~ resp.data", resp.data)
					}
				})
			},

			clickCultive(cultive)
			{
				this.selectCultive = '#'+cultive.DedDescription;
				
				this.getDamageFlawCultive(cultive);
				this.getParamsByCultive(cultive);
				this.getSpecification(cultive);
			},

			editDefect(item)
			{
				this.dialogDamage = true;
				this.damageSelected = item;
				this.DfcToleranceEdit = item.DfcTolerance;
			},

			editSpecifications(item){

				this.dialogSpecifications = true;
				this.specificationsSelected = item;
				console.log('registro seleccionado', this.specificationsSelected)
				this.FsaMinValueEdit = item.FsaMinValue;
				this.FsaMaxValueEdit = item.FsaMaxValue;
			},

			closeDialogEditDefect()
			{
				this.dialogDamage = false;
				this.DfcToleranceEdit = "";
			},

			closeDialogEditSpecifications() {
				this.dialogSpecifications = false;
				this.FsaMinValueEdit = "";
				this.FsaMaxValueEdit = "";
			},

			save()
			{
				console.log(this.DfcToleranceEdit);
				if(this.DfcToleranceEdit == "" || this.DfcToleranceEdit == null)
				{
					this.$fun.alert("Ingrese tolerancia", "warning");
					return;
				}
				this.$fun.alert("¿Seguro de guardar?", "question")
				.then(r => {
					if(r.value){
						this.damageSelected.DfcTolerance = this.DfcToleranceEdit;
						_sDamageFlawCultiveService
						.save(this.damageSelected, this.$fun.getUserID())
						.then(resp => {
							if(resp.status == 200)
							{
								this.$fun.alert("Guardado Correctamente", "success");
								this.closeDialogEditDefect();

							}
						})
					}
				})
				

			},

			saveSpecifications(){



				if(this.FsaMinValueEdit == "" || this.FsaMinValueEdit == null){
					this.$fun.alert("Ingrese un valor mínimo","warning");
					return;
				}

				if(this.FsaMaxValueEdit == "" || this.FsaMaxValueEdit == null){
					this.$fun.alert("Ingrese un valor maximo","warning");
					return;
				}

				this.$fun.alert("¿Seguro de guardar?", "question")
				.then((r)=>{
					if(r.value) {
						this.specificationsSelected.FsaMinValue = this.FsaMinValueEdit;
						this.specificationsSelected.FsaMaxValue = this.FsaMaxValueEdit;

						this.specificationsSelected.SecUpdate = new Date();

						_sFrzTechnicalspecificationsByCultive
						.save(this.specificationsSelected, this.$fun.getUserID())
						.then((r)=>{
							if(r.status==200) {
								this.$fun.alert("guardaado correctamente","success");
								this.closeDialogEditSpecifications();
							}
						})

					}
				})


			},

			saveParams()
			{

			},

			getTypeSampling(items)
			{
				this.itemsTypeSampling = items;
			},

			isSelectedTypeSampling(value, item) {
				value == 1 ? true : false;
			},

			changeTypeSampling(value, item)
			{
				/* console.log('vhange, ', value, item); */
				this.isSelectedTypeSampling(value, item);
				this.observationChange = [
					value, item
				]
			},

		},

		created () {
			this.getCultive();
			this.getTypeSampling();
		},
	}
</script>
